<template>
  <div>
    <!-- <SuspenseLoader :isLoaded="isLoaded"> -->
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>

    <div class="mt-2 mb-4 rounded-md" v-if="isLoaded">
      <div class="full-mode">
        <div class="grid grid-cols-1 md:grid-cols-2 bg-white px-3 py-6">
          <div class="bg-white  flex flex-col items-center">
            <img
              :src="getVehicleManufactureName"
              alt="vehicle"
              class=" w-96 h-96"
            />

            <div
              class="flex items-center justify-center pb-4 m-auto space-x-20"
            >
              <div class="flex items-center">
                <div
                  class="flex items-center justify-center w-12 h-12 rounded-full bg-oGreen"
                >
                  <i
                    class="text-2xl text-white fas fa-thumbs-up"
                    aria-hidden="true"
                  />
                </div>

                <div class="ml-2">
                  <h1 class="font-medium text-charcoal text-28px">
                    {{ feedback.good.toFixed(2) }}%
                  </h1>
                  <span class="font-medium text-gray-500">Good</span>
                </div>
              </div>

              <div class="flex items-center">
                <div
                  class="flex items-center justify-center w-12 h-12 rounded-full bg-oRed"
                >
                  <i
                    class="text-2xl text-white fas fa-thumbs-down"
                    aria-hidden="true"
                  />
                </div>

                <div class="ml-2">
                  <h1 class="font-medium text-charcoal text-28px">
                    {{ feedback.bad.toFixed(2) }}%
                  </h1>
                  <span class="font-medium text-gray-500">Bad</span>
                </div>
              </div>
            </div>
          </div>

          <div class="bg-white">
            <HealthBarBlockItem
              v-for="(measure, measureIndex) in getData"
              :key="`health-measure-${measureIndex}`"
              :title="measure.title"
              :value="measure.value"
              :count="measure.count"
            />
          </div>
        </div>
      </div>
      <div class="responsive-mode">
        <div class="grid grid-cols-1 md:grid-cols-2">
          <div class="bg-white">
            <HealthBarBlockItem
              v-for="(measure, measureIndex) in getData"
              :key="`health-measure-${measureIndex}`"
              :title="measure.title"
              :value="measure.value"
              :count="measure.count"
            />
          </div>
          <div class="bg-white  flex flex-col items-center">
            <img
              :src="getVehicleManufactureName"
              alt="vehicle"
              class=" w-96 h-96 hidden md:block"
            />

            <div
              class="flex items-center justify-center pb-4 m-auto space-x-20"
            >
              <div class="flex items-center">
                <div
                  class="flex items-center justify-center w-12 h-12 rounded-full bg-oGreen"
                >
                  <i
                    class="text-2xl text-white fas fa-thumbs-up"
                    aria-hidden="true"
                  />
                </div>

                <div class="ml-2">
                  <h1 class="font-medium text-charcoal text-28px">
                    {{ feedback.good.toFixed(2) }}%
                  </h1>
                  <span class="font-medium text-gray-500">Good</span>
                </div>
              </div>

              <div class="flex items-center">
                <div
                  class="flex items-center justify-center w-12 h-12 rounded-full bg-oRed"
                >
                  <i
                    class="text-2xl text-white fas fa-thumbs-down"
                    aria-hidden="true"
                  />
                </div>

                <div class="ml-2">
                  <h1 class="font-medium text-charcoal text-28px">
                    {{ feedback.bad.toFixed(2) }}%
                  </h1>
                  <span class="font-medium text-gray-500">Bad</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </SuspenseLoader> -->
  </div>
</template>

<script>
import { VehicleConfig } from '@/config/VehicleConfig'

export default {
  name: 'ViewVehicleHealth',
  components: {
    HealthBarBlockItem: () =>
      import('@/composites/vehicle/health/HealthBarBlockItem.vue'),
    // SuspenseLoader: () => import("@/components/loader/SuspenseLoader.vue")
  },
  data: () => ({
    isLoaded: false,
    isLoading: false,
    err: null,
    feedback: {
      good: 0,
      bad: 0,
    },
    healthMeasures: [],
    vehicleManufactureMap: {
      'freego_(without_cable)': {
        image: require('@/assets/img/vehicles/manufacture/freego.png'),
      },
      'freego': {
        image: require('@/assets/img/vehicles/manufacture/freego.png'),
      },
      'segway': {
        image: require('@/assets/img/vehicles/manufacture/segway.png'),
      },
      'mimida': {
        image: require('@/assets/img/vehicles/manufacture/mimida.jpg'),
      },
      'dynabike': {
        image: require('@/assets/img/vehicles/manufacture/dynabike.png'),
      },
      'ninebot': {
        image: require('@/assets/img/vehicles/manufacture/ninebot.png'),
      },
      'okai': {
        image: require('@/assets/img/vehicles/manufacture/okai.png'),
      },
      'xiaomi': {
        image: require('@/assets/img/vehicles/manufacture/xiaomi.jpg'),
      },
      'lannmarker': {
        image: require('@/assets/img/vehicles/manufacture/lannmarker.png'),
      },
    },
  }),
  computed: {
    id() {
      return this.$route.params.id
    },
    getData() {
      const max = this.healthMeasures.reduce(function(prev, curr) {
        return prev.count > curr.count ? prev : curr
      })

      // serialize the array for the chart
      let arr = this.healthMeasures.map((item) => {
        const relativeValue = (item.count / max.count) * 100
        return { title: item.parts, count: item.count, value: relativeValue }
      })

      // sort by count descedingly
      return arr
        .sort(function(a, b) {
          return a.count - b.count
        })
        .reverse()
    },
    getVehicleManufactureName() {
      const manufactureName = this.$store.getters[
        'vehicleDetails/getVehicleDetails'
      ].manufacturer.name
        .toLowerCase()
        .replaceAll(' ', '_')

      if (this.vehicleManufactureMap[manufactureName]) {
        return this.vehicleManufactureMap[manufactureName].image
      } else return require('@/assets/img/vehicles/manufacture/segway.png')
    },
  },
  async created() {
    let startDate = this.$dayjs()
      .subtract(1, 'day')
      .format('YYYY-MM-DD')
    let endDate = this.$dayjs().format('YYYY-MM-DD')
    await this.fetchData(startDate, endDate)
  },
  methods: {
    async fetchData(startDate, endDate) {
      const endpoint = VehicleConfig.api.healthChart(
        this.id,
        startDate,
        endDate
      )
      this.isLoading = true

      await this.$http
        .get(endpoint)
        .then((res) => {
          this.isLoaded = true
          this.feedback = { ...res.data.data.feedback }
          this.healthMeasures = res.data.data.parts_health

          // console.warn(res.data.data);
          return res.data.data
        })
        .catch((err) => {
          console.warn(err)

          this.err = err
          return err
        })

      this.isLoading = false
    },
  },
}
</script>

<style lang="scss"></style>
